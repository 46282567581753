@import "~antd/dist/antd.less";
@primary-color: #1172EB;

@import url('https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible:ital,wght@0,400;0,700;1,400;1,700&display=swap');

* {
  font-family: 'Atkinson Hyperlegible', sans-serif;
}

h1 {
  font-size: 30px;
  margin: 0;
}

.ant-select-item,
.ant-select-selection-item {
  font-size: 16px;
}

// todo: removes from this less and adds to modal styles as a global style
.ant-modal-wrap::-webkit-scrollbar {
  width: 6px;
}

.ant-modal-wrap::-webkit-scrollbar-thumb {
  background: #d6d6d6;
  border-radius: 3px;
}

.ant-modal-wrap::-webkit-scrollbar-track {
  background: #fcfcfc;
  border-radius: 20px;
}
